.cke_inner {
  z-index: 999999 !important;
}
.Category-new-modal {
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 100%;
    width: 40%;
    height: 100vh;
    display: flex;
    align-items: center;
    margin: 0rem auto;
  }
}
.manage-buttons {
  display: flex;
  align-items: center;
}
.category-page {
  .image-update {
    position: absolute;
    top: 0;
    background: #fcfcfc;
    left: 0;
    border-radius: 100px;
    z-index: 111;
    cursor: pointer;
    svg {
      font-size: 16px;
    }
  }
}

.media-card {
  position: relative;
  padding-bottom: 20px;
}
.media-subcard {
  position: relative;
  background: #fff0;
  padding: 0px;
  img {
    position: relative;
    width: 100%;
    height: 180px;
    object-fit: contain;
    justify-content: center;
  }
  video {
    width: 100%;
    height: 180px !important;
    object-fit: cover;
    justify-content: center;
  }
}
.media-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.image-update-modal{
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button{
    width: 100%;
    justify-content: center;
  }
  .close-button{
    position: absolute;
    right: 10px;
    top: 10px;
    border: 1px solid #eee;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}