.Order-style {
  .btn-height {
    height: 30px;
  }
  .Order-no_data_found {
    padding: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    color: #979797;
  }
  .Order-Top_navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    white-space: nowrap;
    width: 100%;
    background: #f7f7f7;
    h4 {
      font-size: 18px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  .dFlex {
    display: flex;
  }
  .center {
    align-items: center;
  }
  .nowrap {
    white-space: nowrap;
  }
  .mr10 {
    margin-right: 10px;
  }
  .table_search_card {
    position: relative;
  }
  .table_search {
    height: 40px;
    min-width: 250px;
    border: 1px solid #e9e9e9 !important;
    border-radius: 100px;
    background: #f7f7f7;
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    top: 11px;
    right: 10px;
  }
  .datePicker {
    height: 40px;
    border: 1px solid #e9e9e9 !important;
  }
  .Order-Pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
    padding: 10px 10px 10px 10px !important;
    background: #f8f8f7;
    border: 1px solid #eee;
    border-top: none;
    border-bottom: none;
    font-size: 14px;
  }
  .Order-Table_view {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    min-height: 0px;
  }
  .Order-Table_card {
    display: table;
    width: 100%;
  }
  .Order-Table_subcard {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  .Order-Table_item {
    display: table-cell;
    white-space: normal;
  }
  .Order-Table_subcard {
    .text {
      vertical-align: middle !important;
      padding: 7px 12px 7px 12px;
      border: 1px solid #ebeded;
      font-size: 15px;
      background: #f7f7f7;
      border-right: none;
      border-top: none;
    }
    .text:first-child{
      border-left: none !important;
    }
    .text:last-child{
      border-right: none !important;
    }
    .heading {
      vertical-align: middle !important;
      padding: 7px 12px 7px 12px;
      border: 1px solid #ebeded;
      font-size: 15px;
      background: #fafafa;
      border-right: none;
    }
    .heading:first-child{
      border-left: none !important;
    }
    .heading:last-child{
      border-right: none !important;
    }
  }
  .sticky-column {
    right: 0px;
    position: -webkit-sticky;
    position: sticky;
    background-color: #f7f7f7;
  }
}

.new_Modal {
  .modal-dialog {
    max-width: 600px !important;
    width: 100% !important;
  }
  .modal-body {
    padding: 1rem !important;
  }
  .modal-body{
    input{
      height: 45px !important;
    }
  }
}

.input_style {
  height: 45px !important;
}
.input_style:focus {
  box-shadow: none !important;
}
.button_style {
  height: 38px;
}

.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}
.jcc {
  justify-content: center;
}
.jcr {
  justify-content: right;
}
.jcl {
  justify-content: left;
}
.jcend {
  justify-content: flex-end;
}
.tac {
  text-align: center;
}
.df {
  display: flex;
}
.ai {
  align-items: center;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mr20 {
  margin-right: 20px;
}
.w100 {
  width: 100%;
}
.st_button {
  background: #212945 !important;
  border: 1px solid #212945 !important;
  height: 40px !important;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: center !important;
  color: #fcfcfc !important;
}
.st_button:hover {
  background: #212945db !important;
  border: 1px solid #212945db !important;
  height: 40px !important;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 1rem;
  color: #fcfcfc !important;
}
.css-1pu766q-JoyLinearProgress-root::before{
  background-color: #72c585 !important;
}

.css-1yyttxn::before {
  background-color: #72c585 !important;
}
.css-1pu766q-JoyLinearProgress-root{
--LinearProgress-progressThickness: 30px !important;
}
.MuiLinearProgress-colorNeutral{
  --LinearProgress-progressThickness: 30px !important;
}
.css-1yyttxn{
  --LinearProgress-progressThickness: 30px !important;
}