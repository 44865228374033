.loadingBorder {
  background: linear-gradient(
    -45deg,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff,
    #fff,
    #fff,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #23a6d5,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0,
    #fff0
  );
  background-size: 200% 200%;
  animation: gradient 1s ease infinite;
  position: sticky;
  top: 55px;
  z-index: 9999;
  width: 100%;
  height: 3px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loadingBorder {
  position: fixed;
  top: 0px;
  z-index: 9999;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}
.loadingBorder::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #22294573;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: animloader71 2s linear infinite;
          animation: animloader71 2s linear infinite;
}


@-webkit-keyframes animloader71 {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
@keyframes animloader71 {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
 
.container{
  width: 100% !important;
  max-width: 100% !important;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 1px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-0px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
input{
  box-shadow: none !important;
}


p,
small,
span,
div,
b,
ul,
li,
td,
th,
tbody,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
label,
select,
input,
button,
div,
a {
  font-family: "Poppins", sans-serif !important;
}


 