.Account-page {
 
 
  .coloredButton-b{
    background: #212945 !important;
    border: 1px solid #212945 !important;
    height: 40px;
    padding: 0px 10px 0px 10px;
  }
  .coloredButton-b:hover{
    background: #212945d6 !important;
    border: 1px solid #212945d6 !important;
    height: 40px;
    padding: 0px 10px 0px 10px;
  }
}
.padding-card-form{
  padding: 20px 0px 0px 0px;
}