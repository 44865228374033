.Profile-style {
  .Profile-section {
    padding-bottom: 30px;
  }
  .Profile-card {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 7px;
    margin-bottom: 7px;
    justify-content: space-between;
  }
}
