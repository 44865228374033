.Page-not_foung{

    .newStore{
        justify-content: center;
        position: absolute;
        right: 0;
        left: 0;
        height: 100%;
        align-items: center;
        top: 0;
        margin-left: 220px; 
    }
    .ntFoundh{
        font-size: 28px;
        padding-top: 60px;
        color: #a0a1a069;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
    }
}