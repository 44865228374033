.react-datepicker__month-container{
  width: 80% !important;
}
.react-datepicker__time-container{
  width: 20% !important;
}
.react-datepicker{
  width: 100% !important;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
  right: 20% !important;
}