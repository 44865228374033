.headerMenu {
  .table_search_card{
    position: relative;
    margin-right: 20px;
  }
  .table_search::placeholder{
    color: #9c9c9c;
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    top: 1px;
    right: 2px;
  }
  .table_search {
    height: 40px;
    min-width: 280px;
    max-width: 100%;
    border: 1px solid #e9e9e9 !important;
    border-radius: 100px;
    background: #f7f7f7;
  }
  .mr20 {
    margin-right: 20px;
  }
  .side-menu-icon{
    font-size: 20px !important;
  }
  .header_top-heading {
    font-size: 18px;
    font-weight: 600;
    color: #d5d3ce;
  }

  .nameColor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.6rem !important;
    padding-left: 0.5rem !important;
    font-size: 17px !important;
  }

  .dropdown-item {
    padding: 10px 20px !important;
  }
  .dropdown-menu a:hover {
    background: #31375112 !important;
  }
  .dropdown-menu {
    padding: 0 !important;
  }
  .dropdown-item a:hover {
    text-decoration: none !important;
  }

  .side-navigation-panel::-webkit-scrollbar {
    width: 4px;
    // background-color: #f7f7f7;
    background-color: #414c76;
  }
  .side-navigation-panel::-webkit-scrollbar-thumb {
    background-color: #253261;
  }

  .dropdown-toggle::after {
    display: none;
  }

  a {
    color: #5e6e82 !important;
  }

  .dropdown-item:focus {
    background-color: #fff0 !important;
  }
  .topNavBar {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 999;
    background-color: #f7f7f7 !important;
    margin-left: 220px;
    border-bottom: 1px solid #e9e9e9 !important;
    justify-content: space-between;
    height: 55px;
  }

  .dropdown-menu {
    border: 1px solid #e9e9e9 !important;
  }
  .storeDis {
    font-size: 18px;
  }

  .side-navigation-panel {
    width: 220px !important;
    position: fixed;
    height: 100%;
    // background: #151f25;
    // background: #f7f7f7;
    background: #353641;
    border-right: 1px solid #353641;
    overflow: auto;
    overflow-x: hidden;
    padding: 55px 0px 50px 0px;
    scroll-behavior: smooth;
    behavior: "smooth";
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option-selected {
    // background: #eeeeee !important;
    background: #eeeeee12 !important;
  }
  .side-navigation-panel-select-option:hover {
    // background: #f8f8f81a !important;
    background: #fff0 !important;
  }
  .side-navigation-panel-select-inner-option:hover {
    background: #fff0 !important;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option-selected {
    background: #fff0 !important;
  }
  .sideManu-hover:hover {
    // background: #eeeeee !important;
    background: #eeeeee12 !important;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option {
    text-align: left !important;
    padding: 0;
    border-left-width: 0px !important;
  }
  .dropIcons {
    font-size: 8px !important;
    margin-right: 8px;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option {
    padding: 0px 15px 0px 0px;
    border-left-width: 0px !important;
  }
  .side-navigation-panel-select-option-text a {
    padding: 10px 13px 10px 13px;
    display: flex;
    align-items: center;
    width: 220px;
    font-size: 15px;
  }
  .side-navigation-panel-select-inner-option a {
    padding: 7px 13px 7px 13px;
    display: flex;
    align-items: center;
    width: 220px;
  }
  .side-navigation-panel-select-inner-option a:hover {
    text-decoration: none;
  }
  .side-navigation-panel-select-option-text a:hover {
    text-decoration: none;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option
    .side-navigation-panel-select-option-wrap
    .side-navigation-panel-select-option-text {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .side-navigation-panel *,
  .side-navigation-panel:after,
  .side-navigation-panel:before {
    border-color: #fff !important;
  }

  .navTophdng {
    width: 220px;
    text-align: left;
    height: 55px;
    display: flex;
    align-items: center;
    font-size: 22px;
    cursor: auto;
    margin-left: 0;
    background: #353641;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 15px;
    z-index: 111;
  }
  .navTophdngcollapsed {
    width: 80px;
  }
  .syncColor {
    padding: 14px;
  }
  .syncIcon {
    cursor: pointer;
  }
  .navTophdng:hover {
    background-color: #fff0;
  }
  .navbar-collapse {
    justify-content: flex-end;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .side-navigation-panel {
      display: none;
    }
    .topNavBar {
      margin-left: 0;
    }
    .bdrLft {
      display: flex;
      justify-content: flex-end;
    }
    .navbar-collapse {
      text-align: right;
      margin-top: 10px;
    }

    .classNone {
      display: none;
    }
    .classRelative {
      position: relative;
    }
    .mobile_hide {
      display: none !important;
    }
    .desktop_hide {
      display: block !important;
    }
  }
  .desktop_hide {
    display: none;
  }
  .mobile_hide {
    display: block;
  }

  .btn {
    font-size: 15px !important;
  }

  .strNumnv {
    display: flex;
    align-items: center;
  }

  .ImgboxN {
    margin-right: 10px;
  }

  @media only screen and (min-width: 0px) and (max-width: 1080px) {
    .hideOnMob {
      display: none;
    }
  }

  .headerRightCard {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .logoHeader {
    margin: 0;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option
    .side-navigation-panel-select-inner-option-wrap
    .side-navigation-panel-select-inner-option-text {
    margin: 0 !important;
    padding-left: 0px;
  }
}
table,
tbody,
tr,
th,
td,
p,
small,
a,
span,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-icon,
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  svg {
  color: #8f9da0 !important;
}
.dropdown-menu {
  padding: 0 !important;
  min-width: 200px !important;
  border: 1px solid #eee !important;
}
.dropdown-item {
  padding: 5px 15px 5px 15px !important;
}

.sideMenuN {
  width: 220px;
  position: fixed;
  height: 100%;
  // background: #151f25;
  // background: #f7f7f7;
  background: #353641;
  border-right: 1px solid #353641;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  behavior: "smooth";
  transition: 0s;
  padding: 55px 0px 55px 0px;
  z-index: 9999;
}

.menuColor {
  color: #b5b5b5 !important;
}
.menuColor:hover {
  background-color: #ffffff1f !important;
}
.ant-menu-submenu-title:focus {
  background-color: #ffffff1f !important;
}
.menuColor a {
  color: #b5b5b5 !important;
  border: none !important;
  text-decoration: none !important;
  outline: none !important;
  border-bottom: none !important;
  font-size: 1rem;
}
.menuColor a:hover {
  color: #fcfcfc !important;
}

.subMenuColor {
  color: #444 !important;
}

.menuCollapsed {
  margin-left: 80px !important;
}
.bodyCollapsed {
  margin-left: 80px !important;
}
.hideHeaderName {
  display: none;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: #b5b5b5;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff1f !important;
}

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  color: #b5b5b5 !important;
}
.dFlex {
  display: flex;
  align-items: center;
}
.subMenuColor .ant-menu-title-content {
  color: #444;
}
.ant-menu-item,
.ant-menu-submenu-title {
  font-size: 15px;
}

.accountModal {
  position: fixed;
  right: 0;
  max-width: 100%;
  width: 350px;
  top: 54px;
  margin: 0;
  min-height: 0;
  background-color: #fff;
  box-shadow: "1px 3px 1px #9E9E9E";
  height: 100%;
  padding: 15px;
  border: 1px solid #e9e9e9 !important;
  border-top: none;
  .icon {
    display: flex;
    justify-content: right;
    font-size: 18px;
  }
  .accountMenu {
    width: 100%;
    padding: 5px 15px 5px 15px;
    display: flex;
    align-items: center;
  }
}
.blockAccountModal {
  display: block;
}
.hideAccountModal {
  display: none;
}
.click-account {
  cursor: pointer;
}

.addAllIconCard {
  display: flex;
  align-items: center;
  .addAllIcon {
    color: #353641;
    font-size: 26px;
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  transition: border-color 0s, background 0s,
    padding 0s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  transition: opacity 0s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0s,
    color 0s !important;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title{
  padding: 0 calc(50% - 10.5px) !important;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  font-size: 1rem !important;
  padding: 7px 12px !important;
}
.ant-dropdown-menu-title-content a:hover{
  text-decoration: none !important;
}


