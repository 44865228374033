.image-update-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;



  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
    border-radius: 8px;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}

.new-template-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-content {
    overflow: auto;
    height: 100%;
  }
  .html-fallback-tag {
    display: block;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap;
    .html-fallback-card {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      padding-right: 7px;
      margin-bottom: 7px;
      justify-content: space-between;
    }
  }
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 950px;
    width: 100%;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    margin: 0;
    border-radius: 0;
    height: 100%;
    display: block;
    overflow: auto;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}

.new-post-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-content {
    overflow: auto;
    height: 100%;
  }
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 650px;
    width: 100%;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    margin: 0;
    border-radius: 0;
    height: 100%;
    display: block;
    overflow: auto;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}

table {
  .row {
    margin: 0 !important;
    display: inline-table !important;
  }
}
